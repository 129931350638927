.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.modal-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 16rem; /*256px*/
  left: 50%;
  transform: translateX(-50%);
  max-width: min(900px, 75vw);
  max-height: 75vh;
  border: 2px solid orange;
  background: rgba(30, 30, 30, 1);
  padding: 20px;
  border-radius: 5px;
}

.modal-title {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.modal-message {
  color: #ffffff;
}

.modal-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 400px;
  margin-top: 25px;
}

.modal-button {
  font-size: 1rem;
  padding: 0.75rem;
  border-radius: 5px;
  border: 1px solid white;
  background-color: rgba(50, 50, 50, 0.2);
  color: white;
  min-width: 100px;
}

.modal-button:hover {
  border: 1px solid orange;
  color: orange;
}

.modal-close-icon {
  position: absolute;
  color: #ffffff;
  right: 1rem;
  top: 1rem;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.modal-close-icon:hover {
  color: orange;
}
