.card-static {
  border-color: #ffffff !important;
  cursor: default !important;
  background-color: transparent !important;
}
.card-action-dropdown {
  padding: 0px 0px 0px 5px;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  display: block;
}
.card-action-dropdown li:hover {
  color: orange;
}
.snap-button {
  background-color: transparent;
  border-radius: 4px;
}

.snap-button:focus {
  outline: 0;
}

.snap-button-ready {
  color: #ffffff;
  border: 1px solid #ffffff;
}

.snap-button-ready:hover {
  color: orange;
  border: 1px solid orange;
}

.snap-button-delayed {
  color: #aaaaaa;
  border: 1px solid #aaaaaa;
  opacity: 0.75;
}

.snap-widget-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

.content-list-wrapper {
  position: relative;
  display: flex;
}

.content-list-action-container,
.content-list-item-container {
  cursor: pointer;
}

.content-list-action-container,
.content-list-disabled-container {
  border: 1px dotted #aaaaaa;
  background-color: transparent;
  color: #aaaaaa;
}

.content-list-action-container:hover {
  border: 1px dotted orange;
  background-color: #222;
}

.content-list-item-container {
  border: 1px solid #aaa;
  background-color: transparent;
  color: #ffffff;
}

.content-list-item-container:hover {
  border: 2px solid orange;
  background-color: #222;
}

.content-list-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem;
  justify-content: space-between;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  border-radius: 5px;
  user-select: none;
  height: 55px;
  overflow: hidden;
}

.content-list-left {
  flex: 1 1;
  min-width: 0;
}

.content-list-left,
.content-list-right {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: #ffffff;
  font-size: 1rem;
}

.content-list-right {
  justify-content: flex-end;
}

.content-list-icon {
  max-height: 40px;
  max-width: 40px;
  margin-left: 0.25rem;
}

.content-list-caption {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  min-width: 0;
}

.content-list-caption span {
  overflow-wrap: break-word;
}

.content-list-caption-left,
.content-list-caption-right {
  margin-left: 0.5rem;
  justify-content: center;
  min-width: 0;
}

.interactive-action-icons {
  display: flex;
  flex-direction: row;
}
