#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 1rem;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

.invisiblility {
  opacity: 0;
}

.snackbar-message {
  width: 400px;
  height: 60px;
  border-radius: 0.5rem;
  background-color: #333;
  margin: 5px;
  display: grid;
  place-items: center;
  text-align: center;
  padding: 10px;
  fontsize: 1rem;
  color: #fff;
}

.snackbar-message span {
  display: block;
  word-break: wrap;
}

.snackbar-message-wrapper {
  display: flex;
  flex-direction: column-reverse;
  position: fixed;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 30px;
  transitionduration: 2000ms;
}

.animation-floatUpwards {
  animation: floatUpwards ease-in-out infinite;
}

.animation-float {
  animation: float ease-in-out infinite;
}

@keyframes floatUpwards {
  0% {
    opacity: 0;
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(50px);
  }
  5% {
    opacity: 1;
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }
  90% {
    opacity: 1;
    transform: translatey(-20px);
  }
  100% {
    opacity: 0;
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(20px);
  }
}

@keyframes float {
  0% {
    opacity: 0.8;
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    opacity: 1;
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }
  100% {
    opacity: 0.8;
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}
