.master-detail-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
}

.master .card {
  cursor: pointer;
  /*aspect-ratio: 1.77769 / 1;*/
  background-color: black;
  border: 1px solid #999;
}
.master .card:hover {
  border: 1px solid orange;
}

.master .card.selected {
  background-color: black;
  border: 4px solid orange;
}

/*
.master .card .text-right {
  background-color: black;
}
*/

.master {
  max-height: calc(100vh - 8rem);
  display: flex;
  flex-direction: column;
  width: max(20rem, 18.5vw);
  padding-right: 0.25rem;
  overflow: overlay;
}

.master-slide-toolbar {
  background-color: #333;
}

.detail-wrapper {
  flex: 1;
  position: relative;
}

.detail {
  margin-top: 0rem;
  margin-right: 1rem;
  margin-left: 0.5rem;
  max-height: calc(100vh - 8rem);
  /*aspect-ratio: 1.77769/1;*/
  background-color: black;
  border: 1px solid #999;
  box-sizing: content-box !important;
}

.detail:hover {
  border: 1px solid #999;
}

.slide-editor-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.slide_template_editor {
  position: fixed;
  top: 8rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 2rem;
  min-width: 900px;
  max-height: 75vh;
  max-width: 75vw;
  border: 3px solid orange;
  border-radius: 10px;
  color: white;
  background-color: rgba(34, 34, 34, 0.95);
  text-align: center;
  overflow-y: overlay;
}
.slide_template_editor::-webkit-scrollbar {
  background: transparent;
}

.form-media {
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  border: 2px solid transparent;
}
.form-media.selected {
  border: 2px solid orange;
}
.form-media-thumbnail-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.form-media-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  text-align: left;
  color: "#FFFFFF";
}
.form-media-link-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
  width: 100%;
  margin-bottom: 1rem;
}
.form-media-link {
  display: flex;
  flex: 1;
  position: relative;
  height: calc(1.5em + 0.75rem + 2px);
}
.form-media-link:hover {
  color: orange;
}
.form-media-link input {
  cursor: pointer;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 4rem 0.375rem 0.75rem;
  /*font-size: 1rem;
  font-weight: 400;
  margin-left: 5px;*/
}
.form-media-thumbnail-container {
  width: 179px;
  height: 175px;
  padding: 3px;
  margin: 4px 4px 4px 0px;
  /*border: 1px solid #ffffff;*/
  border-radius: 0.5rem;
  display: grid;
  place-items: center;
  position: relative;
  background-color: rgba(30, 30, 30, 0.5);
  overflow: hidden;
  user-select: none;
}
.form-media-thumbnail-container:hover {
  background-color: #222222;
}
.form-media-thumbnail-container img {
  max-height: 169px;
  max-width: 169px;
  border: 1px solid #777;
  border-radius: 0.25rem;
  cursor: pointer;
}
.form-media-lock-icon {
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  background-color: #484848;
  padding: 3px;
  width: 25px;
  height: 25px;
  border-radius: 33%;
  opacity: 0.75;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.form-media-lock-icon:hover {
  color: orange;
  background-color: #565656;
  opacity: 1;
}
.form-media-lock-icon.hovering {
  color: orange !important;
  background-color: #565656 !important;
  opacity: 1 !important;
}
.form-media-container {
  flex: 1;
  padding-left: 10px;
}
.form-media-first-row {
  display: flex;
}

.card-action {
  outline: none;
  color: white;
  background-color: transparent;
  border: none;
  margin-right: 5px;
  margin-left: 5px;
  height: 100%;
  display: grid;
  place-items: center;
  width: 30px;
  height: 30px;
}
.card-action-icon {
  width: 100%;
  height: 100%;
}
.card-action-icon.smaller {
  width: 75%;
  height: 75%;
}
.card-action:hover {
  color: orange;
  cursor: pointer;
}
.card-action:active {
  outline: none;
  border: none;
}
.draggable {
  cursor: move;
}
.resizable-nw,
.resizable-se {
  cursor: nwse-resize;
}
.resizable-ne,
.resizable-sw {
  cursor: nesw-resize;
}
.input-deco-button {
  pointer-events: none;
  position: absolute;
  width: 25px;
  height: 25px;
  right: 15px;
  bottom: 7px;
}

.debugger-layer-id {
  position: absolute;
  width: 168px;
  text-align: center;
  bottom: 0%;
  color: #fff;
  font-weight: 800;
  /*-webkit-text-stroke: 0.5px orange;
  paint-order: stroke fill;*/
  font-size: 1.25rem;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.5);
}
.scale-input-container {
  margin-right: 15px;
  margin-left: 15px;
}
.scale-input-container input {
  height: 38px;
  padding-left: 8px;
}
.input-percent-indicator {
  position: absolute;
  right: 32px;
  bottom: 5px;
  font-size: 12pt;
}
.scedit-row {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
}
.scedit-row div::-webkit-scrollbar {
  background: transparent;
  width: 12px;
}
.scedit-row div::-webkit-scrollbar-thumb {
  background-color: #727272;
  border-radius: 4px;
}
.scedit-row div::-webkit-scrollbar-thumb:hover {
  background-color: #b4b4b4;
}
.scedit-checklist-wrapper {
  flex: 2;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}
.scedit-link-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.scedit-link-input-container,
.scedit-checklist-input-container {
  width: inherit;
  height: 38px;
  background-color: #4c4c4c;
  display: flex;
}
.scedit-btn-highlight:hover {
  color: orange;
}
.scedit-link-confirm-btn,
.scedit-link-undo-btn {
  width: 38px;
  height: inherit;
  display: grid;
  place-items: center;
}
.scedit-links-container,
.scedit-checklist-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.scedit-checklist-input,
.scedit-link-input {
  outline: none;
  margin: 1px;
  flex: 2;
  height: inherit;
  color: #ffffff;
  background-color: #4c4c4c !important;
  padding-left: 8px;
}
.scedit-checklist-input {
  /*border: auto !important;*/
}
.scedit-link-input:nth-child(1) {
  flex: 1;
  margin-right: 8px;
}
.scedit-link-input::placeholder,
.scedit-checklist-input::placeholder,
.scedit-link-input::-webkit-input-placeholder,
.scedit-checklist-input::-webkit-input-placeholder {
  opacity: 1;
  color: #cccccc !important;
}
.scedit-links-box,
.scedit-checklist-box {
  width: inherit;
  overflow: auto;
  background-color: #333333;
  display: flex;
  flex-direction: column;
}
.scedit-link,
.scedit-checklist-item {
  width: 100%;
  display: flex;
  height: 2rem;
  font-size: 1.25rem;
  align-items: center;
  text-align: left;
  padding-left: 0.5rem;
}
.scedit-checklist-item {
  border-bottom: 1px solid #cccccc;
}
.scedit-checklist-item input:not([type="checkbox"]) {
  flex: 1;
  transition-duration: 200ms;
  outline: none;
  height: 1rem;
  font-size: 1rem;
  color: #ffffff;
  background-color: transparent !important;
  padding-left: 8px;
  border: none;
}
.scedit-checklist-item input[type="checkbox"] {
  visibility: visible !important;
  margin-left: 0.5rem;
  height: 1.25rem;
  width: 1.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  content: "";
  border: 1px solid grey;
  display: inline-block;
  vertical-align: -2px;
  border-radius: 4px;
  background-color: #222222;
}
.scedit-checklist-item input[type="checkbox"]:hover {
  background-color: rgba(72, 72, 72, 1);
}
.scedit-checklist-item input[type="checkbox"]:checked {
  background-color: orange;
  border: 1px solid #ffffff;
  /*color: white;*/
}
.scedit-link:nth-child(odd) {
  background-color: #3d3d3d;
}
.scedit-link span {
  flex: 1;
  color: #ffffff;
  width: 100%;
  max-height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.scedit-link-btn {
  height: inherit;
  width: 1.5rem;
  display: grid;
  place-items: center;
  cursor: pointer;
  opacity: 0.75;
}
.scedit-link-edit-btn {
  margin-right: 0.5rem;
}
.scedit-link-edit-btn:hover {
  opacity: 1;
  color: orange;
}
.scedit-link-remove-btn {
  margin-right: 0.5rem;
}
.scedit-link-remove-btn:hover {
  opacity: 1;
  color: #fa8289;
}
.picto-on-slide-container {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  pointer-events: none;
}
.picto-on-slide-container-master {
  bottom: 30px;
  left: 10px;
}
.picto-on-slide-container-detail {
  bottom: 25px;
  left: 25px;
}
.picto-on-slide-master {
  width: 1.25rem;
  height: 1.25rem;
  bottom: 30px;
  left: 10px;
  margin-top: 5px;
}
.picto-on-slide-detail {
  width: 2.5rem;
  height: 2.5rem;
  bottom: 25px;
  left: 25px;
  margin-top: 10px;
}
.input-percentage {
  margin: 4px;
  padding-left: 4px;
  font-weight: bold;
  width: 60px !important;
  text-align: center;
  border-bottom: 2px solid #555 !important;
  background-color: #222 !important;
}
