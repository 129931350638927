/* Tabs  */
.auth-panel {
  background-color: #111;
  color: #5a5959;
}

.auth-tabs .nav-tabs {
  border-bottom: 1px solid orange;
}

/* Inactive tab */
.auth-tabs .nav-link {
  background: #222;
  color: #5a5959;
}

.auth-tabs .nav-link.active {
  background: #333;
  border-color: orange;
  color: white;
}

.auth-tabs .nav-link:hover {
  background: #333;
}

/* Forms  */
/* Forgot password Link */
.forgot-passwd-link {
  color: white;
}
.forgot-passwd-link:hover {
  color: orange;
}

form input,
form textarea,
form select {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 12pt;
  line-height: 1.75em;
  font-weight: 100;
  letter-spacing: 1px;
  text-shadow: 0 0 0.5px rgba(58, 57, 57, 0.25);
  -webkit-text-stroke: 0.25px;
}

form label {
  display: block;
  margin: 0 0 0.5em 0;
}

form input[type="text"],
form input[type="number"],
form input[type="email"],
form input[type="password"],
form select.form-control,
form textarea,
.form-control[readonly] {
  -moz-transition: background-color 0.35s ease-in-out;
  -webkit-transition: background-color 0.35s ease-in-out;
  -ms-transition: background-color 0.35s ease-in-out;
  transition: background-color 0.35s ease-in-out;
  -webkit-appearance: none;
  display: block;
  border: 0;
  background: #333;
  width: 100%;
  /*padding: 0.75em;*/
  border-radius: 0;
  color: white;
}

form input[type="text"]:focus,
form input[type="number"]:focus,
form input[type="email"]:focus,
form input[type="password"]:focus,
form input[type="checkbox"]:focus,
form select:focus.form-control,
form textarea:focus {
  background: #333;
  color: white;
  outline: none;
}
form input[type="text"]:disabled,
form input[type="number"]:disabled,
form input[type="email"]:disabled,
form input[type="password"]:disabled,
form input[type="checkbox"]:disabled,
form select:disabled.form-control,
form textarea:disabled {
  background: #333;
  color: #666;
  outline: none;
}

form input[type="text"],
form input[type="email"],
form input[type="password"],
form select {
  line-height: 1em;
}

form textarea {
  min-height: 12em;
}

.auth-tabs .btn {
  color: white;
  border-color: white;
}
.auth-tabs .btn:focus {
  border-color: rgb(77, 144, 254);
}

form .formerize-placeholder {
  color: #5a5959 !important;
}

form ::-webkit-input-placeholder {
  color: #5a5959 !important;
}

form :-moz-placeholder {
  color: #5a5959 !important;
}

form ::-moz-placeholder {
  color: #5a5959 !important;
}

form :-ms-input-placeholder {
  color: #5a5959 !important;
}

form ::-moz-focus-inner {
  border: 0;
}

form ul.actions {
  margin-bottom: 0;
  text-align: center;
}

.col-form-label {
  text-align: left;
}

.userForm {
  margin-top: 1.5%;
}

.form-control:focus {
  border-color: rgb(77, 144, 254);
  box-shadow: 0 0 0 0.15rem rgba(0, 123, 255, 0.25);
}

.form-error {
  color: #f44336;
}

.form-group .has-error input {
  border: 1px solid #f44336;
}

/* Tabs */

.nav-tabs .nav-link {
  border-radius: 0;
}

/* Authentication-specific */
.authentication-form .alert {
  margin-top: 1rem;
}
