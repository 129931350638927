/* Footer */

.footer {
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4rem;
  line-height: 60px;
}

.legalNotices {
  color: white;
}

.legalNotices:hover {
  color: orange;
}

@media (max-width: 767px) {
  .footer {
    line-height: 30px;
  }

  .legalNotices {
    display: inline-block;
  }
}
