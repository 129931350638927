/* Home-specific */

.card-wrapper {
  margin: 0.5rem 0;
  padding: 0 0.5rem;
}

.card-wrapper .card {
  display: flex;
  flex-direction: column;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  border-radius: 5px;
  padding: 1rem;
  background-color: transparent;
}
.card-wrapper .card#card-add-content,
.card-wrapper .card#card-file-manager {
  border-style: dotted;
  border-color: #aaaaaa;
  color: #aaaaaa;
}
.card-wrapper .card#card-add-content:hover,
.card-wrapper .card#card-file-manager:hover {
  border-color: orange;
}

.card-wrapper .card a {
  margin: auto;
}

.card:hover {
  border-color: orange;
}
.card-content:hover {
  background-color: #222;
  cursor: pointer;
}

.card-wrapper .card .image {
  max-width: 100%;
  height: auto;
}

.card-wrapper .card .caption {
  margin-top: 0.5rem;
  color: white;
}

/*.card-wrapper .card .caption p {
  font-weight: 300;
}*/

.caption span,
h3 {
  font-size: 1.25rem;
}

.dropdown {
  display: inline-block;
}
.home-icon.dropdown-menu,
.home-icon.dropdown-menu:hover {
  background-color: #000a;
  border: 1px solid white;
}
.dropdown-item:hover {
  cursor: pointer;
  background-color: transparent;
}

.project-name {
  display: inline-block;
  background-color: transparent;
  border: none;
  outline: none !important;
  color: white;
  padding-right: 5px;
}
.project-device-label {
  padding-left: 10px;
  color: white;
}
.home-icon {
  background-color: transparent;
  border: none;
  outline: none !important;
  color: white;
}
.home-icon:hover {
  color: orange;
  border: none;
}

.path-white path {
  stroke: #ffffff;
}
.path-white:hover path {
  stroke: orange;
}
.caption {
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: break-word;
}

.column {
  display: flex;
  flex-direction: column;
}
