/* Landing */

.jumbotron {
  margin-top: 5rem;
  border-width: 3px;
  border-style: solid;
  border-color: orange;
  border-radius: 10px;
  color: white;
  background-color: #222;
}

.jumbotron h2 {
  word-break: break-word;
}

.jumbotron .claim {
  font-size: 2em;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 3rem;
  }
}

.btn-signup:hover {
  background: orange;
}

.ReactModalPortal > * {
  opacity: 0;
}

.ReactModal__Overlay {
  transition: opacity 200ms ease-in-out;
  background: rgba(0, 0, 0, 0.5);
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
