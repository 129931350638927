/* Main */

html {
  position: relative;
  min-height: 100%;
}

body {
  background-color: #111;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  text-shadow: 0 0 0.5px rgba(58, 57, 57, 0.25);
  margin-top: 7rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.75em;
}

h2 {
  font-size: 1.75em;
  letter-spacing: 8px;
}

.hmk-form-btn {
  color: white;
  border-color: white;
}
/*.hmk-form-btn:focus {
  border-color: rgb(77, 144, 254);
}*/
.hmk-form-btn:hover,
.hmk-form-btn-ghosted:hover {
  color: orange;
  border-color: orange;
}
.hmk-form-btn-ghosted {
  color: white;
  border-style: dotted;
  border-color: white;
}
form textarea {
  min-height: 0em;
}

.jumbotron {
  position: relative;
}
.hmk-btn-close {
  font-size: 2em;
  color: white;
  position: absolute;
  top: 10px;
  right: 1.5rem;
  cursor: pointer;
}
.hmk-btn-close:hover {
  color: orange;
}

::-webkit-scrollbar {
  background-color: #222;
  width: 15px;
}
::-webkit-scrollbar-track {
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #444;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #6a6a6a;
}
/* remove white square in the corner */
::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

.container-wrapper {
  margin: 2rem 0 8rem 0;
}

/* Cookie consent */

.cookieConsent {
  background: #3a3939;
  width: 100%;
  padding: 1rem;
  color: #fff;
  text-align: center;
}

.cookieConsent button {
  margin-top: 0.5rem;
}

/* Component-specific */

@import "./Authentication/Authentication.css";
@import "./Common/Footer/Footer.css";
@import "./Home/Home.css";
@import "./Landing/Landing.css";
@import "./Navigation/Navigation.css";
