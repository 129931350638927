#timeline {
  background-color: white;
  width: 100%;
  height: 100%;
  /*overflow:hidden;*/
}

.closeMetrics {
  color: #3a3939;
  font-size: 1.5em;
  margin-left: 97%;
  margin-top: -8%;
}
