/* Navigation  */
.navbar {
  border-width: 2px;
  border-style: solid;
  border-color: #333;
  border-radius: 5px;
  padding: 1rem;
  background-color: #222;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: orange;
}

.navbar-dark .navbar-toggler {
  outline: none;
}

.navbar-text {
  color: white;
}

.navbar-brand {
  color: white;
}

.navbar-brand img {
  margin-right: 0.5rem;
}

.navbar-brand:hover {
  color: orange;
}

.btn-signin:hover {
  background: orange;
}

.btn-signout:hover {
  background: orange;
}

.name {
  margin-right: 1em;
}

ul li {
  list-style-type: none;
}

.profile {
  color: white;
  padding-top: 0.5%;
  margin-left: 2vw;
}

.profile:hover {
  color: orange;
  text-decoration: none;
}

.navbar-btn-action {
  margin-right: 1.5%;
  color: white;
  font-size: 1.4em;
  position: relative;
  cursor: pointer;
}

.navbar-btn-action:hover {
  color: orange;
}

.gr_icon path {
  stroke: white;
}

.gr_icon:hover path {
  stroke: orange;
}
/*
@media (max-width: 1023px) {
  .navbar-collapse {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
*/

/*
@media (max-width: 374px) {
  .navbar-brand {
    width: 10%;
    height: 10%;
  }

  .navbar-toggler {
    margin-right: -3%;
  }
}
*/
